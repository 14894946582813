import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TeamScoreboardItemModel, TeamSettings } from 'models';
import { Subject } from 'rxjs';
import { AppState, playersSelectors } from 'store';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-teams-scoreboard',
    templateUrl: './teams-scoreboard.component.html',
    styleUrls: ['../scoreboard.component.scss'],
    imports: [NgIf, NgFor, MatTooltip, DecimalPipe, TranslateModule],
})
export class TeamsScoreboardComponent implements OnInit, OnDestroy {
    #unsubscribe = new Subject<void>();
    #items: Array<TeamScoreboardItemModel> = [];
    notQualified: Array<TeamScoreboardItemModel> = [];

    public get items() {
        return this.#items;
    }

    @Input() set items(value: Array<TeamScoreboardItemModel>) {
        if (!value?.length) {
            this.#items = [];
            this.notQualified = [];
            return;
        }
        this.#items = value.filter((x) => x.qualifies);
        this.notQualified = value.filter((x) => !x.qualifies);
    }
    @Input() height: number;
    settings: TeamSettings;

    constructor(private readonly store: Store<AppState>) {}

    ngOnInit(): void {
        this.store.pipe(select(playersSelectors.selectSettings)).subscribe((settings) => (this.settings = settings));
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }
}
